<template>
	<div id="app">
		<div id="chart"></div>
		<!-- <iframe src="https://sunburst.formatoverde.pt/" width="600" height="600"></iframe> -->
	</div>
</template>

<script>
	import Sunburst from 'sunburst-chart';

	import * as fs from 'fs-web';
	const csv = require('csvtojson');

	const dados = require('./dados.json');

	export default {
		name: 'App',
		data() {
			return {
				dados: {
					name: 'root',
					children: dados,
				},
				splitAt: 17,
			};
		},
		async created() {
			this.fetchFile();
		},
		mounted() {
			Sunburst().data(this.dados).labelOrientation('radial').size('size').color('color').radiusScaleExponent(1).onClick(this.onNodeClick).excludeRoot(true)(
				document.getElementById('chart')
			);

			setTimeout(() => {
				document.querySelectorAll('#chart > div > svg > g > g > g > text.text-stroke').forEach((el) => {
					let words = el.innerHTML.split(' ');
					this.canAdd(words, (arr) => {
						let inject = '';
						let initialY = 0;

						switch (arr.length) {
							case 2:
								initialY = '-0.5em';
								break;

							case 3:
								initialY = '-1em';
								break;

							case 4:
								initialY = '-1em';
								break;

							case 5:
								initialY = '-1em';
								break;

							default:
								initialY = 0;
								break;
						}
						for (let i in arr) {
							inject += `<tspan at="${i}" x="0" dy="${i == 0 ? initialY : '1.2em'}">
					        ${arr[i]}
					       </tspan>`;
						}
						el.innerHTML = inject;
					});
				});
			}, 100);
		},
		methods: {
			onNodeClick(e) {
				if (e && e.link) window.open(e.link, '_blank');
			},
			canAdd(words, callback, index = 0, parsingString = '', finalArr = []) {
				if (words.length > index) {
					if (words[index].length + parsingString.length > this.splitAt) {
						finalArr.push(parsingString.trim());
						return this.canAdd(words, callback, index + 1, words[index], finalArr);
					} else {
						parsingString += ' ' + words[index];
						return this.canAdd(words, callback, index + 1, parsingString, finalArr);
					}
				} else {
					finalArr.push(parsingString.trim());
					return callback(finalArr);
				}
			},
			fetchFile() {
				fetch('/file.csv')
					.then((res) => {
						return res.text();
					})
					.then((data) => {
						csv()
							.fromString(data)
							.then((jsonObj) => {});
					});
			},
		},
	};
</script>

<style>
	.sunburst-viz text {
		font-size: 65% !important;
	}
	@media screen and (max-width: 425px) {
		.sunburst-viz text {
			font-size: 25% !important;
		}
	}
	@media screen and (min-width: 426px) and (max-width: 768px) {
		.sunburst-viz text {
			font-size: 65% !important;
		}
	}

	body {
		margin: 0 !important;
	}
	.text-contour {
		display: none !important;
	}
	.text-stroke {
		fill: white !important;
	}
	.radial-label {
		display: block !important;
	}
	.sunburst-tooltip {
		max-width: unset !important;
	}
</style>
